import axios, { AxiosResponse } from "axios";
import { useMutation, MutateConfig } from "react-query";
import JSONLD from "src/utils/jsonld";
import { v4 as uuid } from "uuid";

import { useApi } from "@sol/sdk/SDKProvider";

import { IResourceResource } from ".";

export type UpdateResourceVariables = {
    resourceUuid: string;
    payload: {
        title?: string;
        url?: string;
        description?: string;
        tags?: string[];
    };
};

export const useUpdateResource = (
    config: MutateConfig<AxiosResponse<IResourceResource>, any, UpdateResourceVariables>,
) => {
    const api = useApi();

    const mutationFn = async (params: UpdateResourceVariables) => {
        const url = params.payload.url;
        let media = undefined;

        try {
            // Here we get image of the page specified by the url in order to set the resource image;
            if (url) {
                // STEP 1: Retrieve the image from url by using a private API and bypassing the
                // CORS issues when accessing external URL
                const { data: image } = await axios.get("/api/website-image", {
                    params: {
                        url,
                    },
                    responseType: "blob",
                });

                // STEP: 2 Upload the image as media
                const formData = new FormData();
                formData.append("file", image);
                formData.append("uuid", uuid());

                media = await api.request({
                    url: "/media",
                    method: "POST",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data: formData,
                });

                // STEP 3: manually link the resource to the media
                media = media.data;
            }
        } finally {
            // STEP 4: Update the resource with the image
            return api.put<IResourceResource>(
                `resources/${params.resourceUuid}`,
                JSONLD.normalize({ ...params.payload, ...(media && { image: media }) }),
            );
        }
    };

    return useMutation<AxiosResponse<IResourceResource>, any, UpdateResourceVariables>(mutationFn, config);
};
