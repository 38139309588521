import { yupResolver } from "@hookform/resolvers/yup";
import isArray from "lodash/isArray";
import React, { ComponentPropsWithoutRef, FocusEvent, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Merge } from "type-fest";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { Loader } from "@sol/icons";
import { Modal } from "@sol/modal";
import { getResourceUuid, ICreateResourceParams, IResourceResource } from "@sol/sdk";
import { UpdateResourceVariables } from "@sol/sdk/resources/useUpdateResource";
import { Button, Container, GhostButton, InputDecorator, TextArea, TextInput } from "@sol/uikit";

import { Text } from "../../uikit";
import TagsPicker from "../shared/pickers/TagsPicker";
import TagIdsPicker from "../shared/pickers/TagsPicker/TagIdsPicker";
import UserAvatar from "../shared/UserAvatar";

const Card = styled.div`
    background: ${({ theme }) => theme.palette.white.base};

    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing[6]};

    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing[9]};

    width: 100%;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > ${Text}:last-of-type {
        color: ${({ theme }) => theme.palette.red.base};
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing[8]};
`;

const Row = styled.div<{ spaced?: boolean }>`
    display: flex;
    align-items: end;

    justify-content: ${({ spaced }) => (spaced ? "space-between" : "unset")};

    gap: ${({ theme }) => theme.spacing[9]};

    > ${InputDecorator} {
        flex: 1;
    }

    > ${Button} {
        margin: 0;
    }
`;

const TITLE_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 300;
const TAGS_MAX_COUNT = 10;

const schema = yup
    .object()
    .shape({
        title: yup.string().required().max(TITLE_MAX_LENGTH),
        url: yup.string().url().required(),
        description: yup.string().required().max(DESCRIPTION_MAX_LENGTH),
        tags: yup.array(yup.string().required()).optional().max(TAGS_MAX_COUNT),
    })
    .required();

export type FormValues = yup.InferType<typeof schema>;

type Props = Merge<
    ComponentPropsWithoutRef<typeof Modal>,
    {
        onCancel: () => void;
        onCreationSubmit: (values: ICreateResourceParams) => void;
        onUpdateSubmit: (variables: UpdateResourceVariables) => void;
        submitting?: boolean;
        defaultValues?: IResourceResource;
        open: boolean;
    }
>;

const createResourceDefaultValues = {
    url: "",
    title: "",
    tags: [],
    description: "",
};

const ResourceCreationModal = ({
    onCancel,
    onCreationSubmit,
    onUpdateSubmit,
    submitting,
    defaultValues,
    open,
    ...props
}: Props) => {
    const [t] = useTranslation();
    const { activeClassroomId } = useActiveClassroom();
    const { user } = useAuthenticatedUser();
    const { control, handleSubmit, reset } = useForm<FormValues>({
        resolver: yupResolver(schema),
        mode: "onTouched",
        criteriaMode: "firstError",
    });

    const prefixId = useMemo(() => {
        const prefix = uuid();

        return (id: string) => `${prefix}::${id}`;
    }, []);

    useEffect(() => {
        if (defaultValues) {
            reset({ ...defaultValues, tags: defaultValues.tags?.map(tag => tag["@id"]) });
        } else {
            reset(createResourceDefaultValues);
        }
    }, [defaultValues]);

    return (
        <Modal
            isOpen={open}
            aria-label={t(`component.home.ResourceCreationModal.title.${defaultValues ? "update" : "creation"}`)}
            {...props}
        >
            <Container>
                <Card>
                    <Header>
                        <Text variant="h4">
                            {t(`component.home.ResourceCreationModal.title.${defaultValues ? "update" : "creation"}`)}
                        </Text>
                        <Text>
                            <sup>*</sup>
                            {t("component.home.ResourceCreationModal.required")}
                        </Text>
                    </Header>
                    <Form
                        onSubmit={handleSubmit(payload => {
                            defaultValues
                                ? onUpdateSubmit({ resourceUuid: getResourceUuid(defaultValues), payload })
                                : activeClassroomId && onCreationSubmit({ ...payload, classroom: activeClassroomId });
                        })}
                        data-cy="add-resource-form"
                    >
                        <Row>
                            <UserAvatar user={user} />
                            <Controller
                                control={control}
                                name="title"
                                render={({ field, fieldState: { invalid, error } }) => {
                                    const id = prefixId(field.name);

                                    return (
                                        <InputDecorator
                                            small
                                            required
                                            htmlFor={id}
                                            label={t("component.home.ResourceCreationModal.fields.title.label")}
                                            helper={t("component.home.ResourceCreationModal.fields.title.helper", {
                                                count: field.value?.length ?? 0,
                                                max: TITLE_MAX_LENGTH,
                                            })}
                                            error={error}
                                        >
                                            {({ errorId }) => (
                                                <TextInput
                                                    {...field}
                                                    id={id}
                                                    data-cy="resource-title"
                                                    disabled={submitting}
                                                    aria-describedby={errorId}
                                                    aria-invalid={invalid}
                                                    variant={invalid ? "error" : "default"}
                                                />
                                            )}
                                        </InputDecorator>
                                    );
                                }}
                            />

                            <Controller
                                control={control}
                                name="url"
                                render={({ field, fieldState: { invalid, error } }) => {
                                    const id = prefixId(field.name);

                                    return (
                                        <InputDecorator
                                            small
                                            required
                                            htmlFor={id}
                                            label={t("component.home.ResourceCreationModal.fields.url.label")}
                                            helper={t("component.home.ResourceCreationModal.fields.url.helper")}
                                            error={error}
                                        >
                                            {({ errorId }) => (
                                                <TextInput
                                                    {...field}
                                                    id={id}
                                                    data-cy="resource-url"
                                                    disabled={submitting}
                                                    aria-describedby={errorId}
                                                    aria-invalid={invalid}
                                                    variant={invalid ? "error" : "default"}
                                                />
                                            )}
                                        </InputDecorator>
                                    );
                                }}
                            />
                        </Row>

                        <Row>
                            <Controller
                                control={control}
                                name="description"
                                render={({ field, fieldState: { invalid, error } }) => {
                                    const id = prefixId(field.name);

                                    return (
                                        <InputDecorator
                                            required
                                            htmlFor={id}
                                            label={t("component.home.ResourceCreationModal.fields.description.label")}
                                            helper={t(
                                                "component.home.ResourceCreationModal.fields.description.helper",
                                                {
                                                    count: field.value?.length ?? 0,
                                                    max: DESCRIPTION_MAX_LENGTH,
                                                },
                                            )}
                                            error={error}
                                        >
                                            {({ errorId }) => (
                                                <TextArea
                                                    {...field}
                                                    id={id}
                                                    data-cy="resource-description"
                                                    disabled={submitting}
                                                    aria-describedby={errorId}
                                                    aria-invalid={invalid}
                                                    variant={invalid ? "error" : "default"}
                                                />
                                            )}
                                        </InputDecorator>
                                    );
                                }}
                            />
                        </Row>

                        <Controller
                            control={control}
                            name="tags"
                            render={({ field: { onBlur, onChange, value }, fieldState: { invalid, error } }) => {
                                return (
                                    <InputDecorator
                                        label={t("component.home.ResourceCreationModal.fields.tags.label")}
                                        helper={t("component.home.ResourceCreationModal.fields.tags.helper", {
                                            count: value?.length ?? 0,
                                            max: TAGS_MAX_COUNT,
                                        })}
                                        error={isArray(error) ? undefined : error}
                                    >
                                        {({ errorId }) => (
                                            <TagIdsPicker
                                                onBlur={(event: FocusEvent<HTMLDivElement>) => {
                                                    // React 17 internally uses the browser's focusin and focusout events for onFocus and onBlur events
                                                    // see https://blog.saeloun.com/2021/05/14/react-17-uses-browse-focusin-focusout-for-onfocus-onblur
                                                    if (!event.currentTarget.contains(event.relatedTarget)) {
                                                        onBlur();
                                                    }
                                                }}
                                                value={value ?? []}
                                                onChange={
                                                    /* Disable tags picker on submit */
                                                    submitting ? () => undefined : onChange
                                                }
                                                data-cy="resource-tags"
                                                aria-describedby={errorId}
                                                aria-invalid={invalid}
                                            />
                                        )}
                                    </InputDecorator>
                                );
                            }}
                        />

                        <Row spaced>
                            <GhostButton type="button" onClick={onCancel} disabled={submitting}>
                                {t("component.home.ResourceCreationModal.cancel")}
                            </GhostButton>

                            <Button
                                type="submit"
                                variant="primary"
                                filled
                                disabled={submitting}
                                loading={submitting}
                                aria-label={
                                    submitting ? t("component.home.ResourceCreationModal.submitting") : undefined
                                }
                            >
                                {submitting ? (
                                    <Loader />
                                ) : (
                                    t(
                                        `component.home.ResourceCreationModal.submit.${
                                            defaultValues ? "edit" : "create"
                                        }`,
                                    )
                                )}
                            </Button>
                        </Row>
                    </Form>
                </Card>
            </Container>
        </Modal>
    );
};

export default styled(ResourceCreationModal)`
    ${Container} {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: start;

        padding: 0;

        ${TagsPicker} {
            margin-top: ${({ theme }) => theme.spacing[4]};
        }
    }
`;
