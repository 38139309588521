import { AnimateSharedLayout } from "framer-motion";
import Link from "next/link";
import React, { useState, ComponentPropsWithoutRef, useRef } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import Pagination from "src/uikit/Pagination";
import ExtendedPager from "src/uikit/Pagination/ExtendedPager";
import SegmentedControls from "src/uikit/SegmentedControls";
import styled from "styled-components";
import { Merge } from "type-fest";

import { useAuthenticatedUser } from "@sol/authentication";
import { Feature, FeatureGuard } from "@sol/features";
import { Plus } from "@sol/icons";
import { Route } from "@sol/routing";
import { IClassroomResource, IResourceResource, useCreateResource, UserRole } from "@sol/sdk";
import { useResources } from "@sol/sdk/resources/useResources";
import { useUpdateResource } from "@sol/sdk/resources/useUpdateResource";
import { Button } from "@sol/uikit";

import ApiErrorAlert from "../ApiErrorAlert";
import ResourceListItem from "../shared/ResourceListItem";
import SearchForm from "../shared/SearchForm";
import { BlockSkeleton } from "../skeletons";
import Card from "./Card";
import ResourceCreationModal from "./ResourceCreationModal";

const DEFAULT_HEIGHT = "510px";

const Loader = styled(BlockSkeleton)`
    height: ${DEFAULT_HEIGHT};
    width: 100%;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const EmptyList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.palette.purple.lightest};
    height: ${DEFAULT_HEIGHT};

    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const FiltersRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: ${({ theme }) => theme.spacing[6]};

    ${SegmentedControls} ul {
        height: 48px;
    }
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    ${ResourceListItem} + ${ResourceListItem} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

export const AUTHOR_TYPE_FILTER_OPTIONS = [
    { id: UserRole.TRAINER, label: "component.home.ResourceCard.authorTypeFilter.trainer" },
    { id: UserRole.LEARNER, label: "component.home.ResourceCard.authorTypeFilter.learner" },
];

const RESOURCES_PER_PAGE = 4;

type CreationModalSettingsType = {
    isOpen: boolean;
    defaultValues?: Partial<IResourceResource>;
};

type Props = Merge<
    ComponentPropsWithoutRef<typeof Card>,
    {
        loading?: boolean;
        classroom?: IClassroomResource;
    }
>;

const ResourcesCard = ({ classroom, loading, className, ...rest }: Props) => {
    const [t] = useTranslation();
    const titleId = useId();
    const ressourcesListContainer = useRef<HTMLDivElement>(null);

    const [searchValue, setSearchValue] = useState("");
    const [search, setSearch] = useState(searchValue);
    const [currentPage, setCurrentPage] = useState(1);
    const [roleFilter, setRoleFilter] = useState(UserRole.TRAINER);
    const [creationModalSettings, setCreationModalSettings] = useState<CreationModalSettingsType>({
        isOpen: false,
        defaultValues: undefined,
    });

    const { user: currentUser } = useAuthenticatedUser();

    const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

    const onRequestSuccess = () => {
        setRoleFilter(currentUser.roles[0]);
        setCreationModalSettings({ isOpen: false, defaultValues: undefined });
        refetchResources();
        setCurrentPage(1);
    };

    const {
        data: resources,
        isLoading: isResourcesLoading,
        refetch: refetchResources,
    } = useResources({
        filters: { classroom, createdBy: roleFilter, term: search },
        pagination: { perPage: RESOURCES_PER_PAGE, page: currentPage },
        enabled: !!classroom,
    });

    const [
        createResource,
        { isLoading: resourceCreating, error: resourceCreationError, reset: resetResourceCreation },
    ] = useCreateResource({
        // IMPORTANT: we should reject the promise in order to avoid
        // to close the modal and refresh the data if there is an API or Network
        // error
        throwOnError: true,
        onSuccess: onRequestSuccess,
    });

    const [updateResource, { isLoading: resourceUpdating, error: resourceUpdateError, reset: resetResourceUpdate }] =
        useUpdateResource({ onSuccess: () => onRequestSuccess() });

    const resourcesCount = resources?.["hydra:member"].length ?? 0;

    const totalPages = resources ? Math.ceil(resources?.["hydra:totalItems"] / RESOURCES_PER_PAGE) : 0;

    let children = <Loader />;

    if (!(loading || isResourcesLoading) && resources) {
        if (resourcesCount > 0) {
            children = (
                <AnimateSharedLayout>
                    <List>
                        {resources["hydra:member"].map(resource => (
                            <ResourceListItem
                                key={resource["@id"]}
                                resource={resource}
                                data-cy="resource-list-item"
                                expanded={expandedItemId === resource["@id"]}
                                setExpanded={(resourceId: string) => {
                                    setExpandedItemId(expandedItemId === resourceId ? undefined : resourceId);
                                }}
                                onDelete={refetchResources}
                                onEdit={defaultValues => setCreationModalSettings({ isOpen: true, defaultValues })}
                            />
                        ))}
                    </List>
                </AnimateSharedLayout>
            );
        } else {
            children = (
                <EmptyList data-cy="resources-empty">
                    <Text variant="label">{t("component.home.ResourceCard.empty")}</Text>
                </EmptyList>
            );
        }
    }

    return (
        <Card
            {...rest}
            className={className}
            title={
                <Text as="h1" variant="h3" id={titleId}>
                    {t("component.home.ResourceCard.title")}
                </Text>
            }
            helper={
                <FeatureGuard feature={Feature.RESOURCE_CREATE}>
                    <Button
                        data-cy="add-resource"
                        filled
                        variant="primary"
                        onClick={() => setCreationModalSettings({ isOpen: true, defaultValues: undefined })}
                    >
                        {t("component.home.ResourceCard.addResource")}
                        <Plus />
                    </Button>
                </FeatureGuard>
            }
        >
            {classroom && (
                <FeatureGuard feature={Feature.RESOURCE_CREATE}>
                    <ResourceCreationModal
                        open={creationModalSettings.isOpen}
                        defaultValues={creationModalSettings.defaultValues}
                        submitting={resourceCreating || resourceUpdating}
                        onCancel={() => setCreationModalSettings({ isOpen: false, defaultValues: undefined })}
                        onCreationSubmit={createResource}
                        onUpdateSubmit={updateResource}
                    />
                </FeatureGuard>
            )}
            <ApiErrorAlert
                error={resourceCreationError || resourceUpdateError}
                dissmiss={() => {
                    resetResourceCreation();
                    resetResourceUpdate();
                }}
            />
            <FiltersRow>
                <SearchForm
                    label={t("component.home.ResourceCard.search.label")}
                    onChange={setSearchValue}
                    onSubmit={() => setSearch(searchValue)}
                    onReset={() => {
                        setSearch("");
                        setSearchValue("");
                    }}
                    searchFieldId="resource_card-search"
                    srOnly={t("component.home.ResourceCard.search.srOnly")}
                    value={searchValue}
                    resetButtonDescribedBy={titleId}
                />
                <SegmentedControls
                    onChange={role => {
                        setRoleFilter(role as UserRole);
                        setCurrentPage(1);
                        setExpandedItemId(undefined);

                        if (ressourcesListContainer.current) {
                            ressourcesListContainer.current.focus();
                        }
                    }}
                    options={AUTHOR_TYPE_FILTER_OPTIONS.map(element => ({
                        ...element,
                        label: t(element.label),
                    }))}
                    title={t("component.home.ResourceCard.authorTypeFilter.title")}
                    value={roleFilter}
                    data-cy="resources-role-filter"
                />
                <Link href={Route.RESOURCES} passHref>
                    <Button as="a" outlined variant="primary">
                        {t("component.home.ResourceCard.goToResourcesPage")}
                    </Button>
                </Link>
            </FiltersRow>
            <div
                aria-live="polite"
                ref={ressourcesListContainer}
                tabIndex={-1}
                className="focus-classes rounded-md outline-none"
            >
                <p className="sr-only">
                    {t("component.home.ResourceCard.search.resultCount", { count: resourcesCount })}
                </p>
                {children}
            </div>

            {totalPages > 1 && (
                <Pagination
                    current={currentPage}
                    onChange={setCurrentPage}
                    total={totalPages}
                    pager={props => <ExtendedPager {...props} />}
                />
            )}
        </Card>
    );
};

export default styled(ResourcesCard)`
    ${Pagination} {
        margin-top: ${({ theme }) => theme.spacing[6]};
    }

    ${Loader} + ${Loader} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;
