import { motion, AnimatePresence } from "framer-motion";
import Image from "next/image";
import { useRef, useState } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { SROnly, Text } from "src/uikit";
import styled from "styled-components";

import { useRestrictedScope } from "@sol/access-control";
import { Feature, FeatureGuard } from "@sol/features";
import { Chevron, Pencil, TrashCan } from "@sol/icons";
import {
    IResourceResource,
    getPlaceholderPicture,
    IUserResource,
    getUserDisplayName,
    useDeleteResource,
    getResourceUuid,
} from "@sol/sdk";
import { IconButton } from "@sol/uikit";
import { SimplePopup, PopupMessageType, PopupType } from "@sol/uikit/molecules/SimplePopup";

import ApiErrorAlert from "../ApiErrorAlert";
import UserAvatar from "./UserAvatar";

const ImageColumn = styled.div`
    min-height: 96px;
    width: 171px;
    position: relative;
    border-radius: ${({ theme }) => `${theme.shape.borderRadius.large} 0 0 ${theme.shape.borderRadius.large}`};
    overflow: hidden;

    > *,
    > * > * {
        border-radius: ${({ theme }) => `${theme.shape.borderRadius.large} 0 0 ${theme.shape.borderRadius.large}`};
        object-fit: cover;
        width: 100% !important;
        height: 100% !important;
    }
`;

const Title = styled(Text)`
    display: inline-block;
    color: ${({ theme }) => theme.palette.purple.base};
    cursor: pointer;
    max-width: 100%;
`;

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${({ theme }) => theme.spacing[4]};

    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[6]}`};
    min-height: 0;
`;

type AuthorBoxProps = {
    author: IUserResource;
    className?: string;
};

const AuthorBox = styled(({ author, className }: AuthorBoxProps) => (
    <div className={className}>
        <UserAvatar size="small" user={author} />
        <Text variant="label">{getUserDisplayName(author)}</Text>
    </div>
))`
    display: flex;
    align-items: center;

    ${UserAvatar} {
        margin-right: ${({ theme }) => theme.spacing[3]};
    }
`;

const TagsBox = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    gap: ${({ theme }) => theme.spacing[3]};
`;

const Tag = styled.li`
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};

    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: 40px;

    --bg-color: ${({ theme }) => theme.palette.white.base};
    --text-color: ${({ theme }) => theme.palette.grey.base};
`;

const ExpandedContent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
`;

const ActionsColumn = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;

    width: 300px;
    margin-top: ${({ theme }) => theme.spacing[7]};
    padding-right: ${({ theme }) => theme.spacing[6]};

    --text-color: ${({ theme }) => theme.palette.grey.base};

    gap: ${({ theme }) => theme.spacing[6]};
`;

const ActionsBox = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[3]};
`;

type Props = {
    resource: IResourceResource;
    expanded: boolean;
    setExpanded: (resourceId: string) => void;
    onDelete: () => void; // triggered when deletion has been successfully processed
    onEdit: (resource: IResourceResource) => void;
};

const ResourceListItem = ({ resource, expanded, setExpanded, onDelete, onEdit, ...props }: Props) => {
    const [t] = useTranslation();
    const confirmButtonRef = useRef(null);
    const titleId = useId();

    const { createdAt, createdBy, url, image, title, tags, description } = resource;

    const scope = useRestrictedScope();

    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [deleteResource, { isLoading, error, reset }] = useDeleteResource({
        onSuccess: () => {
            onDelete();
            setShowDeletionModal(false);
        },
    });

    return (
        <li {...props}>
            <ImageColumn>
                <Image
                    src={image?.url || getPlaceholderPicture(resource)}
                    alt=""
                    layout="intrinsic"
                    objectFit="cover"
                    objectPosition="center"
                    width={171 * 2}
                    height={96 * 2}
                />
            </ImageColumn>
            <ContentColumn>
                <Title id={titleId} href={url} variant="h4" as="a" target="_blank" rel="noopener noreferrer" lines={1}>
                    {title}
                </Title>
                <AnimatePresence>
                    {expanded && (
                        <ExpandedContent
                            layout
                            key={resource["@id"]}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Text data-cy="resource-description">{description}</Text>
                            <TagsBox>
                                {tags.length > 0 &&
                                    tags.map(tag => (
                                        <Tag key={tag["@id"]} data-cy="resource-tag">
                                            <Text variant="subheading">{tag.title}</Text>
                                        </Tag>
                                    ))}
                            </TagsBox>
                        </ExpandedContent>
                    )}
                </AnimatePresence>
                <AuthorBox author={createdBy} />
            </ContentColumn>
            <ActionsColumn>
                <Text variant="subheading">
                    {t("component.shared.ResourceListItem.createdAt", { date: new Date(createdAt) })}
                </Text>
                <ActionsBox>
                    <FeatureGuard feature={Feature.RESOURCE_UPDATE} scope={scope(resource)}>
                        <IconButton
                            variant="secondary"
                            type="button"
                            data-cy="resource-edit-button"
                            onClick={() => onEdit(resource)}
                            aria-describedby={titleId}
                        >
                            <SROnly>{t("component.shared.ResourceListItem.edit", { title })}</SROnly>
                            <Pencil />
                        </IconButton>
                    </FeatureGuard>
                    <FeatureGuard feature={Feature.RESOURCE_DELETE} scope={scope(resource)}>
                        <IconButton
                            data-cy="resource-delete-button"
                            variant="secondary"
                            type="button"
                            onClick={() => setShowDeletionModal(true)}
                            aria-describedby={titleId}
                        >
                            <SROnly>{t("component.shared.ResourceListItem.delete", { title })}</SROnly>
                            <TrashCan />
                        </IconButton>
                    </FeatureGuard>
                </ActionsBox>
                <IconButton
                    onClick={() => setExpanded(resource["@id"])}
                    data-cy="resource-expand-button"
                    aria-describedby={titleId}
                >
                    <SROnly>{t(`component.shared.ResourceListItem.${expanded ? "reduce" : "expand"}`)}</SROnly>
                    <Chevron direction={expanded ? "up" : "down"} />
                </IconButton>
            </ActionsColumn>
            <SimplePopup
                open={showDeletionModal}
                label={t("component.shared.ResourceListItem.deletePopupMessage", { title })}
                isConfirmLoading={isLoading}
                onClickConfirm={() => deleteResource(getResourceUuid(resource))}
                onClickCancel={() => setShowDeletionModal(false)}
                popupType={PopupType.CONFIRM}
                messageType={PopupMessageType.INFO}
                confirmButtonRef={confirmButtonRef}
            />
            <ApiErrorAlert error={error} dissmiss={reset} triggerElement={confirmButtonRef} />
        </li>
    );
};

export default styled(ResourceListItem)`
    display: flex;

    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
`;
