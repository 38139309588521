import clsx from "clsx";
import React, { ReactNode } from "react";
import { useId } from "react-aria";
import { Text } from "src/uikit";
import styled from "styled-components";

const Heading = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[7]};
`;

const Helpers = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[6]};
`;

type Props = {
    title: string | ReactNode;
    helper?: ReactNode;
    className?: string;
    children: ReactNode;
    "data-cy"?: string;
};

const Card = ({ children, title, helper, className, "data-cy": dataCy, ...rest }: Props) => {
    const titleId = useId();
    const isTitleString = typeof title === "string";

    return (
        <section
            tabIndex={0}
            className={`${className} focus-classes`}
            aria-labelledby={titleId}
            {...rest}
            data-cy={clsx("home-card-section", dataCy)}
        >
            <Heading>
                {isTitleString ? (
                    <Text as="h1" variant="h3" id={titleId}>
                        {title}
                    </Text>
                ) : (
                    title
                )}
                <Helpers>{helper}</Helpers>
            </Heading>
            {children}
        </section>
    );
};

export default styled(Card)`
    padding: ${({ theme }) => theme.spacing[7]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    &:focus {
        outline: none;
    }
`;
